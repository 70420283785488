<template>
  <div class="range-picker-wrapper">
    <v-label>Working Hours</v-label>
    <div class="d-flex range-picker mt-2" v-if="!isShiftGroupedTime">
      <TextTimePicker
        :input-value="fromHour"
        :time="fromHour"
        :externalValidation="externalValidation"
        :showValidationErrors="showValidationErrors"
        class="time-picker-from"
        @select="updateFromTime"
      />
      <div class="dash-divider" v-if="showDash">-</div>
      <div class="to-divider" v-else>to</div>
      <TextTimePicker
        :input-value="toHour"
        :time="toHour"
        :externalValidation="externalValidation"
        :showValidationErrors="showValidationErrors"
        class="time-picker-to"
        @select="updateToTime"
      />
    </div>
    <Select
      autocomplete
      placeholder="Select working hours"
      :items="timeOptions"
      item-text="label"
      item-value="value"
      :value="initialValue"
      @input="handleChangeGroupedTime"
      v-if="isShiftGroupedTime"
    />
    <ValidationProvider
      v-slot="{ errors }"
      name="validateHours"
      rules="required"
      v-if="required"
    >
      <v-text-field
        :error-messages="showValidationErrors ? errors : ''"
        readonly
        class="input-hidden"
        :value="timeRange"
      />
    </ValidationProvider>
  </div>
</template>

<script>
import TextTimePicker from "@/components/common/TimePicker/TextTimePicker";
import { ValidationProvider } from "vee-validate";
import Select from "@/components/common/Select";

export default {
  name: "TimeRangePicker",
  components: { TextTimePicker, ValidationProvider, Select },
  props: {
    fromHour: String,
    toHour: String,
    required: Boolean,
    showValidationErrors: Boolean,
    showDash: Boolean
  },
  methods: {
    updateFromTime(fromHour) {
      this.$emit("updateRange", { fromHour, toHour: this.toHour });
    },
    updateToTime(toHour) {
      this.$emit("updateRange", { toHour, fromHour: this.fromHour });
    },
    handleChangeGroupedTime({ fromHour, toHour }) {
      this.$emit("updateRange", { fromHour, toHour });
    }
  },
  computed: {
    timeRange() {
      return this.fromHour && this.toHour
        ? `${this.fromHour}-${this.toHour}`
        : null;
    },
    externalValidation() {
      return Boolean(this.timeRange);
    },
    isShiftGroupedTime() {
      return process.env.VUE_APP_IS_SHIFT_GROUPED_WORKING_HOURS === "true";
    },
    initialValue() {
      return {
        fromHour: this.fromHour,
        toHour: this.toHour
      };
    },
    timeOptions() {
      return [
        {
          label: "Full day (09:00 - 16:00)",
          value: { fromHour: "09:00:00", toHour: "16:00:00" }
        },
        {
          label: "Half day AM (09:00 - 13:00)",
          value: { fromHour: "09:00:00", toHour: "13:00:00" }
        },
        {
          label: "Half day PM (13:00 - 16:00)",
          value: { fromHour: "13:00:00", toHour: "16:00:00" }
        }
      ];
    }
  }
};
</script>

<style lang="scss"></style>
