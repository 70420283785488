import MOCKED_WORKERS from "./workers/worker-list";
import MOCKED_WORKER from "./workers/worker";
import generateId from "uuid/v4";

export const REFERENCE_TYPES = [
  "Professional Reference",
  "Character Reference",
  "Personal Statement"
];

export const RIGHT_TO_WORK_DOCUMENT_TYPES = [
  "Application Registration Card",
  "Biometric Residence Permit",
  "Birth or Adoption Certificate (Channel Islands, Isle of Man or Ireland)",
  "Certificate of Application",
  "Certificate of Registration",
  "Certificate of Naturalisation",
  "Immigration Status Document",
  "Passport (EU, EEA or Swiss)",
  "Passport (UK)",
  "Passport (With Endorsement from the Home Office)",
  "Positive Verification Notice",
  "Residence Card"
];

export const PREVENT_TRAINING_TYPES = [
  "Practitioners",
  "Support Staff",
  "Subcontractors",
  "Leaders and Managers",
  "Host Families",
  "Employers",
  "Governors and Board Members"
];

export const DBS_TYPES = [
  "Basic",
  "Standard",
  "Enhanced",
  "Enhanced Child",
  "Enhanced Adult",
  "Enhanced Child & Adult"
];

export const BOOLEAN_OPTIONS = [
  { label: "Yes", value: true },
  { label: "No", value: false }
];

export const ETHNIC_GROUPS = [
  { header: "White" },
  { label: "English / Welsh / Scottish / Northern Irish / British", id: 1 },
  { label: "Irish", id: 2 },
  { label: "Gypsy or Irish Traveller", id: 3 },
  { label: "Any other White background", id: 4 },
  { divider: true },
  { header: "Mixed / Multiple ethnic groups" },
  { label: "White and Black Caribbean", id: 5 },
  { label: "White and Black African", id: 6 },
  { label: "White and Asian", id: 7 },
  { label: "Any other Mixed / Multiple ethnic background", id: 8 },
  { divider: true },
  { header: "Asian / Asian British" },
  { label: "Indian", id: 9 },
  { label: "Pakistani", id: 10 },
  { label: "Bangladeshi", id: 11 },
  { label: "Chinese", id: 12 },
  { label: "Any other Asian background", id: 13 },
  { divider: true },
  { header: "Black / African / Caribbean / Black British" },
  { label: "African", id: 14 },
  { label: "Caribbean", id: 15 },
  { label: "Any other Black / African / Caribbean background", id: 16 },
  { divider: true },
  { header: "Other ethnic group" },
  { label: "Arab", id: 17 },
  { label: "Any other ethnic group", id: 18 },
  { label: "Prefer not to say", id: 19 }
];

export const GENDERS = ["male", "female"];

export const MOCKED_WORKERS_STATE = {
  workers: MOCKED_WORKERS,
  currentWorker: MOCKED_WORKER
};

export const COMPLIANCE_NAMES = {
  DBS: "DBS",
  HCPC: "HCPC/CCW/SSSC",
  QTS: "QTS/NCTL Qualification",
  REFERENCES: "References",
  EH: "Employment History",
  RTW: "Right to Work",
  BL: "Barred List",
  FTF: "Face-To-Face Interview",
  NIN: "National Insurance Number",
  OCB: "Overseas Criminal Background",
  PT: "Prevent Training",
  POA: "Proof of Address",
  ID: "ID Verification",
  QUALIFICATIONS: "Qualifications",
  DL: "Driving Licence",
  ST: "Safeguarding Training",
  TPC: "Teacher Prohibition Check",
  ECS: "ECS Check",
  AHHA: "Ad Hoc Health Assessment",
  FHA: "Food Handlers Agreement",
  FSQ: "Food Safety Questionnaire",
  IC: "Induction Checklist",
  IB: "Induction Booklets",
  AT: "Allergen Test",
  IT: "Induction Test",
  HST: "H & S Test",
  FST: "Food Safety Test",
  LT: "Literacy Test",
  GDPRT: "GDPR Training",
  HS: "Health & Safety Training",
  CT: "Cybersecurity Training",
  RF: "Registration Form",
  CONTRACT: "Contract",
  SMC: "Social Media Check",
  EDT: "Equality & Diversity Training",
  BPSS: "BPSS"
};

const {
  DBS,
  HCPC,
  QTS,
  REFERENCES,
  EH,
  RTW,
  BL,
  FTF,
  NIN,
  OCB,
  PT,
  POA,
  ID,
  QUALIFICATIONS,
  DL,
  ST,
  TPC,
  ECS,
  AHHA,
  FHA,
  FSQ,
  IC,
  IB,
  AT,
  IT,
  HST,
  FST,
  LT,
  GDPRT,
  HS,
  CT,
  RF,
  CONTRACT,
  SMC,
  EDT,
  BPSS
} = COMPLIANCE_NAMES;

export const COMPLIANCE_CHECKS_WITH_DETAILS = [
  DBS,
  HCPC,
  QTS,
  REFERENCES,
  RTW,
  BL,
  FTF,
  AHHA,
  FHA,
  FSQ,
  IC,
  IB,
  AT,
  IT,
  HST,
  FST,
  LT,
  HS,
  CT,
  GDPRT,
  RF,
  CONTRACT,
  SMC,
  BPSS
];

export const INIT_FILTERS_DATA = {
  search: null,
  AWRstatus: "",
  incomeType: ""
};

export const INITIAL_COMPLIANCES = [
  {
    complianceCheckName: BL,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: DBS,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: EH,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: FTF,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: HCPC,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: OCB,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: PT,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: POA,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: ID,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: NIN,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: QTS,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: QUALIFICATIONS,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: RTW,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: REFERENCES,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: DL,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: ST,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: TPC,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: ECS,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: AHHA,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: FHA,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: FSQ,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: IC,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: IB,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: AT,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: IT,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: HST,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: FST,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: LT,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: HS,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: CT,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: GDPRT,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: RF,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: CONTRACT,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: SMC,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  },
  {
    complianceCheckName: BPSS,
    checkVerified: false,
    documentTypeName: null,
    documentReferenceNumber: null,
    documentValidFromDate: null,
    documentValidToDate: null,
    registeringBody: null,
    checkHasUpdateService: false
  }
];

export const INIT_WORKER_DATA = {
  firstName: null,
  lastName: null,
  email: null,
  phone: null,
  cv: null,
  dob: null,
  gender: "female",
  countryOfBirth: null,
  incomeTypeName: "PAYE",
  incomeType: "PAYE",
  awr: false,
  dda: false,
  ethnicGroupId: 19,
  isBilling: false,
  yearsReferenced: 0,
  address: {},
  company: {},
  companyAddress: {},
  references: [],
  complianceChecks: []
};

export const INITIAL_REFERENCE = {
  id: generateId(),
  referenceTypeName: null,
  periodStartDate: null,
  periodEndDate: null,
  referenceReceivedDate: null,
  isNew: true
};

export const VALIDATIONS = {
  IS_VALID: true,
  RIGHT_TO_WORK: true,
  EMPLOYMENT_HISTORY: true,
  DBSV: true,
  HCPC_CCWW_SSSC: true,
  QTS_NCTL_QUALIFICATION: true,
  PREVENT_TRAINING: true,
  FACE_TO_FACE_INTERVIEW: true,
  BARRED_LIST: true,
  NATIONAL_INSURANCE_NUMBER: true,
  SAFEGUARDING: true,
  HEALTHANDSAFETY: true,
  CYBERSECURITY: true,
  GDPR_TRAINING: true,
  REGISTRATION_FORM: true,
  CONTRACT_VALIDATION: true,
  SOCIAL_MEDIA_CHECK: true,
  DRIVING_LICENCE: true,
  BPSS_CHECK: true,
  FILES: true
};

export const INIT_ADDRESS = {
  isPrimary: true,
  isBilling: true,
  line1: "",
  line2: "",
  line3: "",
  postCode: "",
  country: ""
};

export const STANDARD_COMPLIANCE_CHECKS = [
  {
    label: FTF,
    options: [
      {
        value: "face_to_face_interview",
        label: "Face-to-face interview has been conducted"
      }
    ]
  },
  {
    label: ID,
    options: [
      { value: "identity_checked", label: "ID Check has been performed" }
    ]
  },
  {
    label: POA,
    options: [
      {
        value: "proof_of_address",
        label: "Candidate's proof of address has been verified"
      }
    ]
  },
  {
    label: NIN,
    options: [
      {
        value: "proof_of_NIN",
        label: "Candidate's National Insurance Number has been verified"
      }
    ]
  },
  {
    label: EH,
    references: [],
    options: [
      {
        value: "history_checked",
        label: "Candidate's recent employment history has been checked"
      }
    ]
  },
  {
    label: DL,
    references: [],
    options: [
      {
        value: "driving_license",
        label: "Candidate's has a valid UK Driving License"
      }
    ]
  },
  {
    label: REFERENCES,
    references: [],
    options: [
      {
        value: "references",
        label: "Candidate has a reference"
      }
    ]
  },
  {
    label: RTW,
    options: [
      {
        value: "right_to_work",
        label: "Candidate has the right to work in the UK"
      }
    ]
  },
  {
    label: BPSS,
    options: [
      {
        value: "bpss",
        label: "BPSS"
      }
    ]
  }
];

export const ADDITIONAL_COMPLIANCE_CHECKS = [
  {
    label: QUALIFICATIONS,
    textfield: null,
    options: [
      {
        value: "qualification_checked",
        label: "Candidate's qualification has been verified"
      }
    ]
  },
  {
    label: ST,
    options: [
      {
        value: "safeguarding_checked",
        label: "Candidate has completed Safeguarding training"
      }
    ]
  },
  {
    label: HCPC,
    textfield: "hcpc_ccw_sssc_number",
    options: [
      {
        value: "hcpc_ccw_sssc_checked",
        label: "Candidate's membership of HCPC/CCW/SSSC has been checked"
      }
    ]
  },
  {
    label: QTS,
    textfield: "qts_nctl_qualification_number",
    options: [
      {
        value: "qts_nctl_qualification_checked",
        label: "Candidate's QTS/NCTL qualification has been checked"
      }
    ]
  },
  {
    label: PT,
    textfield: null,
    select: "prevent_traning_type",
    options: [
      {
        value: "prevent_training",
        label: "The candidate has completed Prevent Training"
      }
    ]
  },
  {
    label: BL,
    textfield: null,
    options: [
      {
        value: "barred_list_checked",
        label: "The candidate has been checked against the barred list check"
      }
    ]
  },
  {
    label: TPC,
    textfield: null,
    options: [
      {
        value: "prohibition_checked",
        label:
          "A prohibition from teaching check on the candidates has been conducted"
      }
    ]
  },
  {
    label: OCB,
    textfield: null,
    options: [
      {
        value: "overseas_criminal_background_checked",
        label:
          "An Overseas criminal background check on the candidate has been conducted"
      }
    ]
  },
  {
    label: DBS,
    textfield: null,
    options: [
      {
        value: "dbs_checked",
        label: "Candidate has a DBS check"
      }
    ]
  },
  {
    label: HS,
    options: [
      {
        value: "health_and_safety_training",
        label: "Health & Safety Training"
      }
    ]
  },
  {
    label: CT,
    options: [
      {
        value: "cybersecurity_training",
        label: "Cybersecurity Training"
      }
    ]
  },
  {
    label: GDPRT,
    options: [
      {
        value: "gdpr_training",
        label: "GDPR Training"
      }
    ]
  },
  {
    label: RF,
    options: [
      {
        value: "registration_form",
        label: "Registration Form"
      }
    ]
  },
  {
    label: CONTRACT,
    options: [
      {
        value: "contract",
        label: "Contract"
      }
    ]
  },
  {
    label: SMC,
    options: [
      {
        value: "social_media_check",
        label: "Social Media Check"
      }
    ]
  },
  {
    label: EDT,
    options: [
      {
        value: "equality_diversity_training",
        label: "Equality & Diversity Training"
      }
    ]
  }
];

export const STANDARD_COMPLIANCE_CHECKS_CSP = [
  {
    label: AHHA,
    options: [
      {
        value: "ad_hoc_health_assessment",
        label: "Ad Hoc Health Assessment verified"
      }
    ]
  },
  {
    label: AT,
    options: [
      {
        value: "allergen_test",
        label: "Allergen Test verified"
      }
    ]
  },
  {
    label: ECS,
    options: [
      {
        value: "ecs_check",
        label: "Employer Checking Service verified"
      }
    ]
  },
  {
    label: FHA,
    options: [
      {
        value: "food_handlers_agreement",
        label: "Food Handlers Agreement verified"
      }
    ]
  },
  {
    label: FSQ,
    options: [
      {
        value: "food_safety_questionnaire",
        label: "Food Safety Questionnaire verified"
      }
    ]
  },
  {
    label: FST,
    options: [
      {
        value: "food_safety_test",
        label: "Food Safety Test verified"
      }
    ]
  },
  {
    label: HST,
    options: [
      {
        value: "h_s_test",
        label: "H & S Test verified"
      }
    ]
  },
  {
    label: IB,
    options: [
      {
        value: "induction_booklets",
        label: "Induction Booklets verified"
      }
    ]
  },
  {
    label: IC,
    options: [
      {
        value: "induction_checklist",
        label: "Induction Checklist verified"
      }
    ]
  },
  {
    label: IT,
    options: [
      {
        value: "induction test",
        label: "Induction Test verified"
      }
    ]
  },
  {
    label: LT,
    options: [
      {
        value: "literacy_test",
        label: "Literacy Test verified"
      }
    ]
  },
  {
    label: RTW,
    options: [
      {
        value: "right_to_work",
        label: "Candidate has the right to work in the UK"
      }
    ]
  }
];

export const AWR_FILTER_OPTIONS = [
  { label: "Pre-AWR", value: false },
  { label: "Post-AWR", value: true }
];

export const TAB_INDEXES = { PROFILE: 0, ACTIVITY: 1 };

export const CREATE_WORKER_STEPS = [
  "basic",
  "income",
  "experience",
  "standardCompliance",
  ...(process.env.VUE_APP_HIDE_COMPLIANCE_FOR_CSP !== "true"
    ? ["additionalCompliance"]
    : []),
  "documents",
  "diversity"
];
