import JSONApi from "devour-client";
import store from "@/store";
import {
  isUnauthorized,
  HTTP_UNAUTHORIZED_MESSAGE
} from "@/utils/requestUtils";
import {
  clearLocalStorageItems,
  getAuthLogoutUrl
} from "@/services/APIService";
import { SET_AUTH_DIALOG_VISIBILITY } from "@/store/modules/global/mutation-types";
import { fromAuth } from "@/store/modules/auth";
import { SET_PERMISSIONS } from "@/store/modules/auth/mutation-types";
import { has, includes, get } from "lodash";
import bookingModel, {
  BOOKING_MODEL,
  UPDATE_PO_NUMBER,
  bookingModelActions
} from "@/models/booking-model";
import submitForAllShiftsModel, {
  SUBMIT_FOR_ALL_SHIFTS_MODEL
} from "@/models/booking-submit-model";
import addressModel, { ADDRESS_MODEL } from "@/models/address-model";
import fieldNecessityModel, {
  FIELD_NECESSITY_MODEL
} from "@/models/field-necessity-model";
import timesheetModel, {
  TIMESHEET_MODEL,
  timesheetModelActions
} from "@/models/timesheet-model";
import userModel, {
  USER_MODEL,
  USER_SELF_MODEL,
  USER_STAFF_MODEL,
  INVITE_USER,
  RESET_PASSWORD
} from "@/models/user-model";
import userRoleModel, { USER_ROLE_MODEL } from "@/models/user-role-model";
import roleModel, { ROLE_MODEL } from "@/models/role-model";
import shiftPatternModel, {
  SHIFT_PATTERN_MODEL
} from "@/models/shift-patterns-model";
import applicationModel, {
  APPLICATION_MODEL,
  applicationActions
} from "@/models/application-model";
import commentModel, { COMMENT_MODEL } from "@/models/comment-model";
import expenseModel, { EXPENSE_MODEL } from "@/models/expense-model";
import entryModel, { ENTRY_MODEL } from "@/models/entry-model";
import eventModel, { EVENT_MODEL } from "@/models/event-model";
import summaryModel, { SUMMARY_MODEL } from "@/models/summary-model";
import shiftModel, {
  SHIFT_MODEL,
  shiftModelActions
} from "@/models/shift-model";
import bookingTemplateModel, {
  BOOKING_TEMPLATE_MODEL
} from "@/models/booking-template-model";
import payRateTemplateModel, {
  PAY_RATE_TEMPLATE_MODEL
} from "@/models/pay-rate-template-model";
import payRateModel, { PAY_RATE_MODEL } from "@/models/pay-rate-model";
import payRateItemModel, {
  PAY_RATE_ITEM_MODEL
} from "@/models/pay-rate-item-model";
import payRateItem, { PAY_RATE_ITEM } from "@/models/pay-rate-item";
import organisationModel, {
  ORGANISATION_MODEL,
  ORGANISATIONS_MODEL,
  INTERMEDIARY_COMPANY_MODEL,
  organisationModelActions
} from "@/models/organisation-model";
import hireReasonModel, { HIRE_REASON_MODEL } from "@/models/hire-reason-model";
import agencyModel, { AGENCY_MODEL } from "@/models/agency-model";
import rejectionReasonModel, {
  REJECTION_REASON_MODEL
} from "@/models/rejection-reason-model";
import publishingScheduleModel, {
  PUBLISHING_SCHEDULE_MODEL
} from "@/models/publishing-schedule-model";
import scheduleTierModel, {
  SCHEDULE_TIER_MODEL
} from "@/models/schedule-tier-model";
import publishingScheduleTemplateModel, {
  PUBLISHING_SCHEDULE_TEMPLATE_MODEL
} from "@/models/publishing-schedule-template-model";
import applicationShiftModel, {
  APPLICATION_SHIFT_MODEL
} from "@/models/application-shift-model";
import assessmentModel, { ASSESSMENT_MODEL } from "@/models/assessment-model";
import referenceModel, { REFERENCE_MODEL } from "@/models/reference-model";
import fileModel, { FILE_MODEL } from "@/models/file-model";
import {
  reportModel,
  reportFieldModel,
  reportFilterModel,
  reportGroupingModel,
  reportChartModel,
  reportSharedWithUserModel,
  REPORT_MODEL,
  REPORT_CHART_MODEL,
  REPORT_FIELD_MODEL,
  REPORT_FILTER_MODEL,
  REPORT_GROUPING_MODEL,
  REPORT_SHARED_WITH_USER_MODEL
} from "@/models/report-model";
import bookingApproverModel, {
  BOOKING_APPROVER_MODEL
} from "@/models/booking-approver-model";
import complianceModel, { COMPLIANCE_MODEL } from "@/models/compliance-model";
import countryModel, { COUNTRY_MODEL } from "@/models/country-model";
import invoiceModel, { INVOICE_MODEL } from "@/models/invoice-model";
import payrollModel, { PAYROLL_MODEL } from "@/models/payroll-model";
import userSettingsModel, {
  USER_SETTINGS_MODEL
} from "@/models/user-settings-model";
import notificationModel, {
  NOTIFICATION_MODEL
} from "@/models/notifications/notification-model";
import emailNotificationsModel, {
  EMAIL_NOTIFICATIONS_MODEL
} from "@/models/notifications/emails/email-notifications-model";
import applicationNotificationsModel, {
  APPLICATION_NOTIFICATIONS_MODEL
} from "@/models/notifications/emails/application-notifications-model";
import bookingNotificationsModel, {
  BOOKING_NOTIFICATIONS_MODEL
} from "@/models/notifications/emails/booking-notifications-model";
import timesheetNotificationsModel, {
  TIMESHEET_NOTIFICATIONS_MODEL
} from "@/models/notifications/emails/timesheet-notifications-model";
import commentNotificationsModel, {
  COMMENT_NOTIFICATIONS_MODEL
} from "@/models/notifications/emails/comment-notifications-model";
import applicationShiftsStatusModel, {
  APPLICATION_SHIFTS_STATUS_MODEL
} from "@/models/application-shifts-status-model";
import requirementModel, {
  REQUIREMENT_MODEL
} from "@/models/requirement-model";
import tagModel, { TAG_MODEL } from "@/models/tag-model";
import costCategoryModel, { COST_CATEGORY } from "@/models/cost-category-model";
import templateCostModel, {
  TEMPLATE_COST_MODEL
} from "@/models/template-cost-model";
import { getToken, getImpersonateUserId } from "@/services/utils";
import { NS_SET_API_AVAILABILITY } from "@/store/modules/layout/mutation-types";
import { NS_SET_API_ERROR } from "@/store/modules/auth/mutation-types";
import budgetModel, { BUDGET_MODEL } from "@/models/budget-model";
import clientInvoiceSetModel, {
  CLIENT_INVOICE_SET_MODEL
} from "@/models/client-invoice-set-model";
import exportModel, { EXPORT_MODEL } from "@/models/export-model";
import userExportModel, { USER_EXPORT_MODEL } from "@/models/user-export-model";
import quotaModel, { QUOTA_MODEL } from "@/models/quota-model";
import timeUnitModel, { TIME_UNIT_MODEL } from "@/models/time-unit-model";
import complianceCheckTypeModel, {
  COMPLIANCE_CHECK_TYPE_MODEL
} from "@/models/compliance-type-model";

const NESTED_DATA_TYPES = ["timesheets"];
const SELF_DETAILS_ENDPOINT = "self";

const shouldPermissionsBeNested = data => {
  return typeof data === "object" && includes(NESTED_DATA_TYPES, data.type);
};

const manageActionForUnauthorizedUser = () => {
  clearLocalStorageItems();
  window.location.href = getAuthLogoutUrl();
};

const formatToNestedPermissions = entity =>
  Object.fromEntries(
    Object.entries(entity.meta.permissions).map(([permission, isPermitted]) => {
      const [section, ...nestedOperation] = permission.split(".");
      const operation = nestedOperation.join(".");
      if (operation) {
        return [`${section}[${entity.id}].${operation}`, isPermitted];
      }
      return [`${section}[${entity.id}]`, isPermitted];
    })
  );

const jsonApi = commit => {
  const token = getToken();
  const jsonApi = new JSONApi({
    apiUrl: `${process.env.VUE_APP_API_URL}/api/v1`,
    bearer: token
  });
  store.commit(NS_SET_API_AVAILABILITY, false);
  store.commit(NS_SET_API_ERROR, false);
  jsonApi.define(BOOKING_MODEL, bookingModel);
  jsonApi.define(UPDATE_PO_NUMBER, bookingModel);
  jsonApi.define(SUBMIT_FOR_ALL_SHIFTS_MODEL, submitForAllShiftsModel);
  bookingModelActions.forEach(action => jsonApi.define(action, bookingModel));
  jsonApi.define(ADDRESS_MODEL, addressModel);
  jsonApi.define(USER_MODEL, userModel);
  jsonApi.define(USER_SELF_MODEL, userModel);
  jsonApi.define(USER_STAFF_MODEL, userModel);
  jsonApi.define(INVITE_USER, userModel);
  jsonApi.define(RESET_PASSWORD, userModel);
  Object.values(applicationActions).forEach(variant =>
    jsonApi.define(variant, applicationModel)
  );
  jsonApi.define(SHIFT_PATTERN_MODEL, shiftPatternModel);
  jsonApi.define(APPLICATION_MODEL, applicationModel);
  jsonApi.define(APPLICATION_SHIFT_MODEL, applicationShiftModel);
  jsonApi.define(COMMENT_MODEL, commentModel);
  timesheetModelActions.forEach(action =>
    jsonApi.define(action, timesheetModel)
  );
  jsonApi.define(TIMESHEET_MODEL, timesheetModel);
  jsonApi.define(EXPENSE_MODEL, expenseModel);
  jsonApi.define(ENTRY_MODEL, entryModel);
  jsonApi.define(BOOKING_TEMPLATE_MODEL, bookingTemplateModel);
  jsonApi.define(PAY_RATE_TEMPLATE_MODEL, payRateTemplateModel);
  jsonApi.define(PAY_RATE_MODEL, payRateModel);
  jsonApi.define(PAY_RATE_ITEM_MODEL, payRateItemModel);
  jsonApi.define(PAY_RATE_ITEM, payRateItem);
  jsonApi.define(EVENT_MODEL, eventModel);
  jsonApi.define(SUMMARY_MODEL, summaryModel);
  jsonApi.define(SHIFT_MODEL, shiftModel);
  shiftModelActions.forEach(action => jsonApi.define(action, shiftModel));
  Object.values(organisationModelActions).forEach(variant =>
    jsonApi.define(variant, organisationModel)
  );
  jsonApi.define(ORGANISATION_MODEL, organisationModel);
  jsonApi.define(ORGANISATIONS_MODEL, organisationModel);
  jsonApi.define(INTERMEDIARY_COMPANY_MODEL, organisationModel);
  jsonApi.define(HIRE_REASON_MODEL, hireReasonModel);
  jsonApi.define(REJECTION_REASON_MODEL, rejectionReasonModel);
  jsonApi.define(PUBLISHING_SCHEDULE_MODEL, publishingScheduleModel);
  jsonApi.define(SCHEDULE_TIER_MODEL, scheduleTierModel);
  jsonApi.define(
    PUBLISHING_SCHEDULE_TEMPLATE_MODEL,
    publishingScheduleTemplateModel
  );
  jsonApi.define(ASSESSMENT_MODEL, assessmentModel);
  jsonApi.define(REFERENCE_MODEL, referenceModel);
  jsonApi.define(FILE_MODEL, fileModel);
  jsonApi.define(REPORT_MODEL, reportModel);
  jsonApi.define(REPORT_FIELD_MODEL, reportFieldModel);
  jsonApi.define(REPORT_FILTER_MODEL, reportFilterModel);
  jsonApi.define(REPORT_GROUPING_MODEL, reportGroupingModel);
  jsonApi.define(REPORT_CHART_MODEL, reportChartModel);
  jsonApi.define(REPORT_SHARED_WITH_USER_MODEL, reportSharedWithUserModel);
  jsonApi.define(COMPLIANCE_MODEL, complianceModel);
  jsonApi.define(BOOKING_APPROVER_MODEL, bookingApproverModel);
  jsonApi.define(COUNTRY_MODEL, countryModel);
  jsonApi.define(INVOICE_MODEL, invoiceModel);
  jsonApi.define(USER_SETTINGS_MODEL, userSettingsModel);
  jsonApi.define(NOTIFICATION_MODEL, notificationModel);
  jsonApi.define(EMAIL_NOTIFICATIONS_MODEL, emailNotificationsModel);
  jsonApi.define(
    APPLICATION_NOTIFICATIONS_MODEL,
    applicationNotificationsModel
  );
  jsonApi.define(BOOKING_NOTIFICATIONS_MODEL, bookingNotificationsModel);
  jsonApi.define(TIMESHEET_NOTIFICATIONS_MODEL, timesheetNotificationsModel);
  jsonApi.define(COMMENT_NOTIFICATIONS_MODEL, commentNotificationsModel);
  jsonApi.define(USER_ROLE_MODEL, userRoleModel);
  jsonApi.define(ROLE_MODEL, roleModel);
  jsonApi.define(PAYROLL_MODEL, payrollModel);
  jsonApi.define(AGENCY_MODEL, agencyModel);
  jsonApi.define(APPLICATION_SHIFTS_STATUS_MODEL, applicationShiftsStatusModel);
  jsonApi.define(FIELD_NECESSITY_MODEL, fieldNecessityModel);
  jsonApi.define(REQUIREMENT_MODEL, requirementModel);
  jsonApi.define(TAG_MODEL, tagModel);
  jsonApi.define(COST_CATEGORY, costCategoryModel);
  jsonApi.define(TEMPLATE_COST_MODEL, templateCostModel);
  jsonApi.define(BUDGET_MODEL, budgetModel);
  jsonApi.define(CLIENT_INVOICE_SET_MODEL, clientInvoiceSetModel);
  jsonApi.define(EXPORT_MODEL, exportModel);
  jsonApi.define(USER_EXPORT_MODEL, userExportModel);
  jsonApi.define(QUOTA_MODEL, quotaModel);
  jsonApi.define(TIME_UNIT_MODEL, timeUnitModel);
  jsonApi.define(COMPLIANCE_CHECK_TYPE_MODEL, complianceCheckTypeModel);

  const requestMiddleware = {
    name: "add-id-to-request-data",
    req: payload => {
      if (payload.req.method === "PATCH") {
        const id = payload.req.url.split("/").pop();
        payload.req.data.data.id = id;
      }
      return payload;
    }
  };

  const errorMiddleware = {
    name: "errors-fix",
    error: payload => {
      if (isUnauthorized(payload ? payload.response : payload)) {
        const url = get(payload, "response.config.url");
        const isAlreadyLoggedIn = getToken();
        if (includes(url, SELF_DETAILS_ENDPOINT) && isAlreadyLoggedIn) {
          return manageActionForUnauthorizedUser(payload);
        }
        store.commit(SET_AUTH_DIALOG_VISIBILITY, true);
        return {
          message: HTTP_UNAUTHORIZED_MESSAGE
        };
      }
      if (payload && payload.response && payload.response.data) {
        const { status } = payload.response;
        if (status && status === 500) {
          store.commit(NS_SET_API_ERROR, true);
        } else {
          store.commit(NS_SET_API_ERROR, false);
        }
        return payload.response.data;
      } else {
        store.commit(NS_SET_API_AVAILABILITY, false);
      }
      throw payload;
    }
  };
  jsonApi.insertMiddlewareBefore("axios-request", requestMiddleware);
  jsonApi.replaceMiddleware("errors", errorMiddleware);
  jsonApi.insertMiddlewareBefore("response", {
    name: "permissions",
    res: payload => {
      const body = payload.res.data.data;
      if (has(body, "meta.permissions")) {
        let permissions = body.meta.permissions;
        if (shouldPermissionsBeNested(body)) {
          permissions = formatToNestedPermissions(body);
        }
        commit(fromAuth(SET_PERMISSIONS), permissions, {
          root: true
        });
      } else if (has(body, "[0].meta.permissions")) {
        body.forEach(entity => {
          const permissions = formatToNestedPermissions(entity);
          commit(fromAuth(SET_PERMISSIONS), permissions, {
            root: true
          });
        });
      }
      return payload;
    }
  });

  jsonApi.errorBuilder = error => {
    const { title, detail, meta } = error;
    return { title, detail, meta };
  };

  jsonApi.headers["Accept"] = "application/json";

  const impersonateUserId = getImpersonateUserId();
  if (impersonateUserId) {
    jsonApi.headers["X-Impersonate"] = impersonateUserId;
  }

  return jsonApi;
};

export default jsonApi;
