import { map } from "lodash";

export const GET_BOOKING_TEMPLATES = "GET_BOOKING_TEMPLATES";
export const GET_BOOKING_TEMPLATE = "GET_BOOKING_TEMPLATE";
export const GET_COMPLIANCE_CHECKS = "GET_COMPLIANCE_CHECKS";
export const GET_INCOME_TYPES = "GET_INCOME_TYPES";
export const GET_LOCATIONS = "GET_LOCATIONS";
export const GET_REASONS_FOR_HIRE = "GET_REASONS_FOR_HIRE";
export const GET_REJECTION_REASONS = "GET_REJECTION_REASONS";
export const GET_REQUIREMENTS = "GET_REQUIREMENTS";
export const GET_COST_CODES = "GET_COST_CODES";
export const GET_IS_FETCHING_DATA = "GET_IS_FETCHING_DATA";
export const GET_INCOME_TYPES_OPTIONS = "GET_INCOME_TYPES_OPTIONS";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_IS_FETCHING_FIELD_NECESSITIES =
  "GET_IS_FETCHING_FIELD_NECESSITIES";
export const GET_FIELD_NECESSITIES = "GET_FIELD_NECESSITIES";
export const GET_COMPLIANCE_REQUIREMENTS = "GET_COMPLIANCE_REQUIREMENTS";
export const GET_IS_FETCHING_COMPLIANCE_REQUIREMENTS =
  "GET_IS_FETCHING_COMPLIANCE_REQUIREMENTS";
export const GET_OTHER_REQUIREMENTS = "GET_OTHER_REQUIREMENTS";
export const GET_IS_FETCHING_OTHER_REQUIREMENTS =
  "GET_IS_FETCHING_OTHER_REQUIREMENTS";
export const GET_PUBLISHING_SCHEDULE_TAGS = "GET_PUBLISHING_SCHEDULE_TAGS";
export const GET_IS_FETCHING_PUBLISHING_SCHEDULE_TAGS =
  "GET_IS_FETCHING_PUBLISHING_SCHEDULE_TAGS";
export const GET_IS_FETCHING_COST_CATEGORIES =
  "GET_IS_FETCHING_COST_CATEGORIES";
export const GET_COST_CATEGORIES = "GET_COST_CATEGORIES";
export const GET_COMPLIANCE_CHECK_TYPES = "GET_COMPLIANCE_CHECK_TYPES";

const bookingsGetters = {
  [GET_BOOKING_TEMPLATES]: state => state.bookingTemplates,
  [GET_BOOKING_TEMPLATE]: state => state.currentBookingTemplate,
  [GET_INCOME_TYPES]: state => state.incomeTypes,
  [GET_LOCATIONS]: state => state.locations,
  [GET_REASONS_FOR_HIRE]: state => state.reasonsForHire,
  [GET_COMPLIANCE_CHECKS]: state => state.complianceChecks,
  [GET_REJECTION_REASONS]: state => state.rejectionReasons,
  [GET_REQUIREMENTS]: state => state.requirements,
  [GET_COST_CODES]: state => state.costCodes,
  [GET_IS_FETCHING_DATA]: state => state.isFetchingData,
  [GET_INCOME_TYPES_OPTIONS]: state => {
    return map(state.incomeTypes, type => {
      const { id, attributes } = type;
      return { label: attributes.name, value: id };
    });
  },
  [GET_COUNTRIES]: state => {
    return map(state.countries, country => ({
      ...country,
      label: country.countryName,
      value: country.currencyCode
    }));
  },
  [GET_IS_FETCHING_FIELD_NECESSITIES]: state =>
    state.isFetchingFieldNecessities,
  [GET_FIELD_NECESSITIES]: state => state.fieldNecessities,
  [GET_COMPLIANCE_REQUIREMENTS]: state => state.complianceRequirements,
  [GET_IS_FETCHING_COMPLIANCE_REQUIREMENTS]: state =>
    state.isFetchingComplianceRequirements,
  [GET_OTHER_REQUIREMENTS]: state => state.otherRequirements,
  [GET_IS_FETCHING_OTHER_REQUIREMENTS]: state =>
    state.isFetchingOtherRequirements,
  [GET_PUBLISHING_SCHEDULE_TAGS]: state => {
    return map(
      state.publishingScheduleTags,
      publishingScheduleTag => publishingScheduleTag.name
    );
  },
  [GET_IS_FETCHING_PUBLISHING_SCHEDULE_TAGS]: state =>
    state.isFetchingPublishingScheduleTags,
  [GET_IS_FETCHING_COST_CATEGORIES]: state => state.isFetchingCostCategories,
  [GET_COST_CATEGORIES]: state => state.costCategories,
  [GET_COMPLIANCE_CHECK_TYPES]: state => {
    return map(state.complianceCheckTypes, "name");
  }
};

export default bookingsGetters;
