<template>
  <div class="shifts">
    <div v-if="isLoading">
      <v-skeleton-loader v-for="i in 10" :key="i" type="list-item" />
    </div>
    <div v-else>
      <div class="shift-list">
        <div v-for="(shift, index) in shiftsToDisplay" :key="index">
          <div class="shift-item">
            <ShiftDetails
              :shift="shift"
              :showCheckBox="Boolean(selectedShiftsCount)"
              :isSelected="isSelected(shift)"
              @select="selectShift(shift)"
            />
          </div>
        </div>
      </div>
    </div>
    <BaseFooter class="relative">
      <MultipleSelectAction
        v-show="selectedShiftsCount"
        :selectedCount="selectedShiftsCount"
        :isSelectAll="isSelectAll"
        @selectAll="selectAllShifts"
        @cancel="clearSelectedShifts"
      />
    </BaseFooter>
    <slot name="pagination" />
  </div>
</template>

<script>
import { filter, findIndex, last, size, some, reduce } from "lodash";
import ShiftDetails from "@/components/bookings/ShiftDetails";
import { groupByDate, groupByMonth } from "@/utils/helpers";
import { isDateInTheFuture } from "@/utils/time";
import MultipleSelectAction from "@/components/common/Button/MultipleSelectAction";
import BaseFooter from "@/components/common/BaseFooter";

export default {
  name: "ShiftList",
  components: {
    ShiftDetails,
    MultipleSelectAction,
    BaseFooter
  },
  props: {
    shifts: Array,
    isLoading: Boolean,
    showStatus: Boolean,
    customProgressWidth: String,
    hideProgress: Boolean,
    hasDateFilter: Boolean,
    showAllShifts: Boolean
  },
  data() {
    return {
      selectedShifts: [],
      lastExpiredShiftId: null,
      isSelectAllPage: false,
      selectMultiple: true
    };
  },
  computed: {
    currentShifts() {
      return reduce(
        this.shifts,
        (currentShifts, shift) => {
          if (isDateInTheFuture(shift.startDate)) {
            currentShifts.push({ ...shift });
          }
          return currentShifts;
        },
        []
      );
    },
    groupedShifts() {
      return groupByMonth(groupByDate({ items: this.currentShifts }));
    },
    selectedShiftsCount() {
      return size(this.selectedShifts);
    },
    areMultipleShiftSelected() {
      return this.selectedShiftsCount > 1;
    },
    previouslySelectedShiftIndex() {
      return this.selectedShiftsCount
        ? findIndex(this.currentShifts, last(this.selectedShifts))
        : -1;
    },
    isSelectAll() {
      return this.selectedShiftsCount === size(this.currentShifts);
    },
    shiftsToDisplay() {
      return this.hasDateFilter || this.showAllShifts
        ? this.shifts
        : this.currentShifts;
    }
  },
  methods: {
    selectShift(shift) {
      if (this.isShiftAlreadySelected(shift)) {
        this.unselectShift(shift);
      } else {
        this.selectedShifts = [...this.selectedShifts, shift];
      }
      this.emitSelectedShifts();
    },
    isShiftAlreadySelected(shift) {
      return some(this.selectedShifts, ({ id }) => id === shift.id);
    },
    unselectShift(shift) {
      this.selectedShifts = filter(
        this.selectedShifts,
        ({ id }) => id !== shift.id
      );
      this.emitSelectedShifts();
    },
    getContiguousShifts(shift) {
      const currentShiftIndex = findIndex(this.currentShifts, shift);
      const selectDown = currentShiftIndex > this.previouslySelectedShiftIndex;

      return this.currentShifts.filter((shift, index) => {
        if (this.isSelected(shift)) return false;
        return selectDown
          ? this.canBeSelectedDown(index, currentShiftIndex)
          : this.canBeSelectedUp(index, currentShiftIndex);
      });
    },
    canBeSelectedDown(index, currentShiftIndex) {
      return (
        index <= currentShiftIndex && index > this.previouslySelectedShiftIndex
      );
    },
    canBeSelectedUp(index, currentShiftIndex) {
      return (
        index < this.previouslySelectedShiftIndex && index >= currentShiftIndex
      );
    },
    selectContiguousShifts(shift) {
      if (this.isShiftAlreadySelected(shift)) {
        this.unselectShift(shift);
      } else {
        this.selectedShifts = [
          ...this.selectedShifts,
          ...this.getContiguousShifts(shift)
        ];
      }
      this.emitSelectedShifts();
    },
    isSelected(shift) {
      return some(this.selectedShifts, shift);
    },
    clearSelectedShifts() {
      this.selectedShifts = [];
      this.$emit("onSelectAllShifts", false);
      this.emitSelectedShifts();
    },
    emitSelectedShifts() {
      this.$emit("onSelectShifts", this.selectedShifts);
    },
    selectAllShifts() {
      this.selectedShifts = [...this.shiftsToDisplay];
      this.emitSelectedShifts();
    }
  },
  watch: {
    isLoading(newValue) {
      if (newValue) {
        this.clearSelectedShifts();
      }
    }
  }
};
</script>

<style lang="scss"></style>
