<template>
  <v-container class="submit-workers-dialog col-12 col-md-8">
    <h1 class="workers-title">Shifts</h1>
    <p class="description">
      Select shifts to submit workers for as part of the booking
    </p>
    <div v-if="showNoDataMessage" class="text-error mb-5">
      You don’t have available shifts
    </div>
    <div v-if="isLoadingOnCreate">
      <v-skeleton-loader v-for="i in 2" :key="i" type="list-item" />
    </div>
    <RadioButton
      v-else-if="availableShifts.length && !isLoadingOnCreate"
      @select="onSelectMode"
      :selectedValue="selectedOption"
      :options="options"
    />
    <div class="booking-shifts px-1" v-if="showShifts">
      <div v-if="showShiftsValidationMessage" class="text-error mb-5">
        At least one shift must be selected
      </div>
      <ShiftList
        :isLoading="isLoadingFromPagination"
        :shifts="availableShifts"
        selectMultiple
        @onSelectShifts="onSelectShifts"
        :showAllShifts="true"
        hideProgress
      />
    </div>
  </v-container>
</template>

<script>
import RadioButton from "@/components/common/RadioButton";
import { createNamespacedHelpers } from "vuex";
import { filter, size, includes } from "lodash";
import {
  BOOKINGS_NAMESPACE,
  FETCH_BOOKING_SHIFTS
} from "@/store/modules/bookings/actions";
import { GET_BOOKING_SHIFTS } from "@/store/modules/bookings/getters";
import { isPermissioned } from "@/utils/permissions";
import ShiftList from "@/components/bookings/ShiftList";
import { paginationMixin } from "@/mixins/pagination.mixin";
import { AUTH_NAMESPACE } from "@/store/modules/auth";

const { mapActions, mapGetters } = createNamespacedHelpers(BOOKINGS_NAMESPACE);
const { mapState: mapAuthState } = createNamespacedHelpers(AUTH_NAMESPACE);

export default {
  name: "SubmitWorkersForBooking",
  mixins: [paginationMixin],
  components: {
    RadioButton,
    ShiftList
  },
  props: {
    showValidationErrors: Boolean
  },
  inject: ["currentBookingId"],
  data() {
    return {
      selectedOption: "all",
      selectedShifts: [],
      options: [
        {
          label: "Submit for all shifts",
          value: "all"
        },
        {
          label: "Submit for specific shifts...",
          value: "specific"
        }
      ],
      isLoadingOnCreate: false,
      isLoadingFromPagination: false
    };
  },
  computed: {
    ...mapGetters({
      bookingShifts: GET_BOOKING_SHIFTS
    }),
    ...mapAuthState(["userData"]),
    showShifts() {
      return this.selectedOption === "specific";
    },
    showShiftsValidationMessage() {
      return (
        this.selectedOption === "specific" &&
        this.showValidationErrors &&
        !size(this.selectedShifts)
      );
    },
    showNoDataMessage() {
      return (
        (!this.isLoadingOnCreate || this.showValidationErrors) &&
        !this.availableShifts.length
      );
    },
    availableShifts() {
      return filter(this.bookingShifts, shift => {
        return isPermissioned(`shifts[${shift.id}].submit-candidate`);
      });
    },
    isMSPAdmin() {
      return this.userData && includes(this.userData.roles, "msp admin");
    }
  },
  async created() {
    this.isLoadingOnCreate = true;
    this.updatePageSize(10);
    try {
      await this.loadShifts();
      const canSubmitForAllShifts = this.availableShifts.length;
      this.$emit("onSubmitForAllShifts", canSubmitForAllShifts);
    } finally {
      this.isLoadingOnCreate = false;
    }
  },
  methods: {
    ...mapActions({
      fetchBookingShifts: FETCH_BOOKING_SHIFTS
    }),
    onSelectShifts(shifts) {
      this.selectedShifts = shifts;
      this.$emit("onChange", this.selectedShifts);
    },
    async loadShiftsFromPagination() {
      this.isLoadingFromPagination = true;
      try {
        await this.loadShifts();
      } finally {
        this.isLoadingFromPagination = false;
      }
    },
    async loadShifts() {
      const { meta } = await this.fetchBookingShifts({
        id: this.currentBookingId,
        params: this.getRequestParams()
      });
      this.updatePageDetails(meta);
    },
    getRequestParams() {
      return {
        filter: {
          "can-submit-candidate": true
        },
        "page[size]": this.page.pageSize,
        "page[number]": this.page.currentPage
      };
    },
    onSelectMode(val) {
      const canSubmitForAllShifts =
        val === "all" && this.availableShifts.length;
      this.$emit("onSubmitForAllShifts", canSubmitForAllShifts);
      this.clearSelectedShifts();
      this.selectedOption = val;
    },
    clearSelectedShifts() {
      this.selectedShifts = [];
      this.$emit("onChange", this.selectedShifts);
    }
  }
};
</script>
<style lang="scss"></style>
